import React from "react";
import "./ProductCategories.sass";
import CarouselCaption from "../../../components/CarouselCaption/CarouselCaption";

const ProductCategories = () => {
  return (
    <div className="product-category-container">
      <div className="product-category-content-1">
        <div className="category-divider-1">
          <div className="category-holder">
            <span className="text-title">Product Categories</span>
            <span className="product-categoriex-span-para">
              Our selection features an extensive array of shrimp products
              available both raw and cooked, these products can be tailored
              in packaging and presentation to meet the specific needs of our
              customers.
            </span>
          </div>
         
        </div>
      </div>
      <div className="category-holder-2" >
            <CarouselCaption />
          </div>
    </div>
  );
};

export default ProductCategories;
