import React,{useState} from "react";
import "./Footer.sass";
import Assets from "../../assets/Assets";
// import InputField from "../../components/InputField/InputField";
// import TextArea from "../../components/InputField/TextArea";
import SecondaryButton from "../../components/Button/SecondaryButton/SecondaryButton";
import { useLocation } from "react-router-dom";

const Footer = () => {
  const location = useLocation();
  console.log("Current pathname:", location.pathname); // Ensure this logs the correct path

  // Exclude Footer on /contact page
  const hideFooter = location.pathname === "/contact";

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [formValues, setFormValues] = useState({
    name: "",
    mobile: "",
    email: "",
    message: "",
  });
  const [errors, setErrors] = useState({});

  const openModal = () => setModalIsOpen(true);

  const closeModal = () => setModalIsOpen(false);

  // Handle form input changes
  const handleChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  // Validation for email and mobile
  const validateForm = () => {
    const { mobile, email } = formValues;
    let formErrors = {};

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const mobileRegex = /^[0-9]+$/;

    if (!mobileRegex.test(mobile)) {
      formErrors.mobile = "Please enter a valid mobile number.";
    }

    if (!emailRegex.test(email)) {
      formErrors.email = "Please enter a valid email address.";
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0; // Return true if no errors
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Perform validation
    if (validateForm()) {
      const apiUrl = "https://ssbandco.net/api/send-mail";
      const formData = new FormData();
      formData.append("name", formValues.name);
      formData.append("mobile", formValues.mobile);
      formData.append("email", formValues.email);
      formData.append("message", formValues.message);
      formData.append("to", "Info@ssbandco.net"); 

      fetch(apiUrl, {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("Form submitted successfully:", data);
          openModal(); // Open success modal
          setFormValues({
            name: "",
            mobile: "",
            email: "",
            message: "",
          });
        })
        .catch((error) => {
          console.error("Error submitting form:", error);
        });
    }
  };

  return (
    <>
      <div className="footer">
        <div className="footer-whole">
          {!hideFooter && (
            <div className="footer-sub">
              <div className="footer-sub-left">
                <div className="footer-left-content">
                  <img src={Assets.Logo} alt="logo" className="footer-logo " />
                  <span className="footer-left-span-content">
                    SSB & Co. casts a distinguished presence in the global
                    marketplace, principally serving as an eminent buyer's
                    representative within the Frozen Seafood industry.
                  </span>
                  <div className="footer-social-medias">
                    <div className="social-medias-container">
                      <div>
                        <img src={Assets.Email} alt="email" />
                      </div>
                      <span className="social-medias-span">
                        <a href="mailto:info@ssbandco.net">info@ssbandco.net</a>
                      </span>
                    </div>
                    <div className="social-medias-container">
                      <div>
                        <img src={Assets.Phone} alt="phone" />
                      </div>
                      <span className="social-medias-span">
                        <a href="tel:+918056777546 +91 44 26267317">M: +91 8056 777 546 | T: +91 44 26267317</a>
                      </span>
                    </div>
                    <div className="social-medias-container">
                      <div>
                        <img src={Assets.Location} alt="location" />
                      </div>
                      <span className="company-address social-medias-span">
                      A: No.20 (62/A),AA 2nd Street, Anna Nagar, Chennai - 600040.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="footer-sub-right">
                <div className="footer-right-content">
                  <span className="request-form-title">
                    Request for Call Back
                  </span>
                  <div className="footer-forms">
                      <div className="input-group">
                        <label className="input-group__label c colored-background" htmlFor="name">Name</label>
                        <input
                          className="input-group__input c"
                          type="text"
                          name="name"
                          value={formValues.name}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="input-group ">
                        <label className="input-group__label c colored-background" htmlFor="mobile">Mobile Number</label>
                        <input
                          className="input-group__input c"
                          type="text"
                          name="mobile"
                          value={formValues.mobile}
                          onChange={handleChange}
                        />
                        {errors.mobile && <span className="error-text">{errors.mobile}</span>}
                      </div>
                      <div className="input-group ">
                        <label className="input-group__label c colored-background" htmlFor="email">Email ID</label>
                        <input
                          className="input-group__input c"
                          type="text"
                          name="email"
                          value={formValues.email}
                          onChange={handleChange}
                        />
                        {errors.email && <span className="error-text">{errors.email}</span>}
                      </div>
                      <div className="input-group ">
                        <label className="input-group__label c colored-background" htmlFor="message">Message</label>
                        <textarea
                          className="input-group__input c"
                          name="message"
                          value={formValues.message}
                          onChange={handleChange}
                          rows={7}
                        />
                      </div>
                      <div className="button-container">
                        <SecondaryButton type="submit" onClick={handleSubmit}>Send Message</SecondaryButton>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          )}
          <div className="footer-copy">
            {!hideFooter && <div className="line"></div>}
            <div className="footer-rights">
              <span className="company-rights-name">
                2024, SSB & CO All rights reserved
              </span>
              <span className="company-rights-name">
                Designed by{" "}
                <span className="company-name">
                  <a href="https://www.kartoffel.in/" target="blank">
                    Kartoffel Technologies
                  </a>
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
