import React from "react";
import Meta from "../../components/metaData/Meta";
import "./Products.sass";
import OurProducts from "./OurProducts/OurProducts";
import ProductCategories from "./ProductCategories/ProductCategories";
import BestSelers from "./BestSelers/BestSelers";
const Products = () => {
  return (
    <div className="whole-container">
      <Meta
        title="SSB | Products"
        description="Description of the About page"
      />
      <div class="background-container">
        <OurProducts />
      </div>
      <div className="product-categories-container">
        <ProductCategories />
      </div>
      {/* <div className="product-categories-container">
        <BestSelers />
      </div> */}
    </div>
  );
};

export default Products;
