import React from "react";
import "./OurProducts.sass";
import MultiRowCarousel from "../../../components/MultiRowCarousel/MultiRowCarousel";
import PrimaryButton from "../../../components/Button/PrimaryButton/PrimaryButton";
import { useNavigate } from "react-router-dom";
const OurProducts = () => {
  const navigate = useNavigate();
  const goTo = () => {
    navigate("/products");
  };
  return (
    <>
      <div className="product-container-old">
        <span className="text-title">Our Products</span>
        <div className="desc-container">
          <span className="product-desc">
            At SSB & Co., we present an exquisite selection of shrimp, including
            the sweet Vannamei and the succulent Black Tiger. Our offerings,
            available in both raw and cooked forms, are tailored to suit every
            preference, beautifully packaged to meet your specifications.
            Experience the richness of the farm bounty through our varied and
            flavourful assortment.
          </span>
          <div className="multi-caro-container">
            <MultiRowCarousel />
          </div>
        </div>
      </div>

      <div className="btn-container">
        <PrimaryButton onClick={goTo}>Know More</PrimaryButton>
      </div>
    </>
  );
};

export default OurProducts;
