import React from "react";
import "./Home.sass";
// import { formatDate } from "../../utills/helpers";
import Meta from "../../components/metaData/Meta";
import TopCarousels from "./TopCarousels/TopCarousels";
import Services from "./Services/Services";
import WhoWeAre from "./WhoWeAre/WhoWeAre";
import OurProducts from "./OurProducts/OurProducts";

const   Home = () => {
  return (
    <div className="home">
      <Meta
        title="SSB | Home"
        description="Seafood is often celebrated for its health benefits, providing essential nutrients such as omega-3 fatty acids, vitamins, and minerals "
      />
      <TopCarousels />
      <div className="service-container">
        <Services />
      </div>
      <div className="who-we-are-container">
        <WhoWeAre />
      </div>
      <div className="product-container">
        <OurProducts />
      </div>
    </div>
  );
};

export default Home;
