import React from "react";
import "./Expertise.sass";
import Assets from "../../../assets/Assets";

const Expertise = () => {
  return (
    <div className="expertise-1 container">
      <div>
        <span className="text-title">Expertise at Every Stage</span>
      </div>
      <div className="expertise-2">
        <div className="expertise-3">
          <img src={Assets.One} alt="one" />
          <span className="expertise-3-span">
            Our offerings are bolstered by adept sales and marketing, ensuring
            efficient distribution to numerous outlets daily. Our network
            includes restaurants, cafes, hotels, supermarkets, and other food
            service establishments, guaranteeing wide-reaching delivery of our
            products.
          </span>
        </div>
        <div className="expertise-3">
          <img src={Assets.Two} alt="two" />
          <span className="expertise-3-span">
            With a legacy of over a decade, SSB&CO's prowess in procurement,
            quality control, packaging, and logistical management of seafood for
            an international clientele is unparalleled. We understand the
            intricacies of the global market, prioritizing top-tier quality and
            packaging standards.​
          </span>
        </div>
        <div className="expertise-3">
          <img src={Assets.Three} alt="three" />
          <span className="expertise-3-span">
            Our clientele spans notable supermarket chains and food industries
            in France, Norway, the UK, the Netherlands, Canada, and Austria. We
            deliver both Individually Quick Frozen (IQF) products and block
            forms to meet varying industry needs. 
          </span>
        </div>
      </div>
    </div>
  );
};

export default Expertise;
