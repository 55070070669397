import React from "react";
import "./OurProducts.sass";
import CarouselImgwithText from "../../../components/CarouselImgwithText/CarouselImgwithText";

const OurProducts = () => {
  return (
    <div className="products-1">
      <div className="container-poducts">
        <div className="container-sub-poducts">
          <span className="text-title">Our Products</span>
        </div>
        <div className="container-content container">
          <span className="container-content-2">
            Welcome to our exclusive collection where quality meets variety. We
            take pride in offering an assortment of shrimp that not only
            satisfies demand but exceeds expectations. Each species in our
            selection is chosen for its unique flavor, texture and culinary
            versatility, ensuring that our customers have access to the best the
            ocean has to offer. Explore our range, featuring specially
            emphasized, sought-after types that cater to connoisseurs and
            casual diners alike. Delight in the diversity and indulge in the
            quality with every choice you make here.
          </span>
        </div>
        <div className="category-container-2">
          <CarouselImgwithText />
        </div>
      </div>
    </div>
  );
};

export default OurProducts;
