// src/routes/Routes.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Layout from "./layout/Layout/Layout";
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import ContactUsPage from "./pages/Contact/ContactUsPage";
import Gallery from "./pages/Gallery/Gallery";
import OurService from "./pages/OurServices/OurService";
import Products from "./pages/Products/Products";
import ScrollToTopOnRouteChange from "./utills/ScrollToTopOnRouteChange";
import DetailsPage from "./pages/Products/DetailsPage/DetailsPage";
// const basename = "/ssb_website";
const RoutesComponent = () => (
  <Router basename="/">
    <ScrollToTopOnRouteChange />
    <Layout>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<ContactUsPage />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/ourservices" element={<OurService />} />
        <Route path="/products" element={<Products />} />
        <Route path="/products/details" element={<DetailsPage />} />
      </Routes>
    </Layout>
  </Router>
);

export default RoutesComponent;
