import React from "react";
import "./WeOffer.sass";
import CardCarousel from "../../../components/CardCarousel/CardCarousel";

const WeOffer = () => {
  return (
    <div>
      <div className="weoffer-1">
        <span className="text-title">What We Offer</span>
      </div>
      <div className="weoffer-2 container-fluid">
        <CardCarousel />
      </div>
    </div>
  );
};

export default WeOffer;
