import React from "react";
import "./HumbleBegining.sass";
import Assets from "../../../assets/Assets";

const HumbleBegining = () => {
  return (
    <div className="humble-1">
      <div className="container-humble">
        <div className="container-sub-humble">
          <span className="text-title">
            Humble Beginnings and Sustained Growth
          </span>
        </div>
        <img src={Assets.Humble} alt="humble" className="humble-img" />
        <div className="container-content">
          <span className="container-content-2">
            In the dynamic seascape of the Frozen Seafood industry, SSB&CO
            stands as a pillar of excellence and innovation. From our
            origins, we have committed ourselves to enhancing value, forging a
            nexus between global scale and local expertise. As a premier buyer's
            representative, our goal is to build lasting partnerships that
            support our shared growth.
            <br />
            <br />
            SSB&CO's journey is built on enhancing both global and local value
            within the industry, fostering collective success with our
            partners. We have nurtured long-term relationships with suppliers,
            distributors, and end-users, instilling stability and mutual
            confidence to invest in our industry's growth. Recognized as a preferred partner for frozen food 
            across multiple regions including  Europe, UK,  USA, Canada, Japan, UAE, China, Vietnam Etc. we've expanded alongside our
            partners, diversifying into an extensive range of products from
            seafood to various frozen goods.
          </span>
        </div>
      </div>
    </div>
  );
};

export default HumbleBegining;
