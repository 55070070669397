// import Logo from "./SVG/ssb logo-0.svg";
// import Logo from "./SVG/ssb-logo-white.png";
import Logo from "./SVG/SSB-Logo.png";
// import Logo from "./SVG/ssb-logo.jpeg";
import Carousel2 from "./Images/Rectangle 55 (1).png";
import conatctban from "./Images/conatctban.svg";
import Banner2 from "./Images/White-Banner.png";
// import Banner2 from "./SVG/WhiteBanner.svg";
// import Banner2 from "./Images/White-Banner.webp"
import Email from "./SVG/email.svg";
import Location from "./SVG/location.svg";
import Phone from "./SVG/phone.svg";
import MarketInsight from "./SVG/MarketInsights.svg";
import WhoWe from "./SVG/WhoWeAre.svg";
import Right1 from "./SVG/Right 1.svg";
import Left1 from "./SVG/Left 1.svg";
import succestk from "./Icons/succestk.svg";
import Humble from "./Images/Humble.webp";
import One from "./SVG/One.svg";
import Two from "./SVG/Two.svg";
import Three from "./SVG/Three.svg";
import Arrow1 from "./SVG/Arrow-1.svg";
import Arrow2 from "./SVG/Arrow-2.svg";

import Offer1 from "./SVG/Offer-1.svg";
import Offer2 from "./SVG/Offer-2.svg";
import Offer3 from "./SVG/Offer-3.svg";
import Offer4 from "./SVG/Offer-4.svg";
import Offer5 from "./SVG/Offer-5.svg";

import Our1 from "./Images/Our1.png";
import Our2 from "./Images/Our2.png";
import Our3 from "./Images/Our3.png";
import Our4 from "./Images/Our4.png";
import Our5 from "./Images/Our5.png";
import Our6 from "./Images/Our6.png";
import Our7 from "./Images/Our7.png";
import Our8 from "./Images/Our8.png";

import Service2 from "./SVG/Service2.svg";
import Service3 from "./SVG/Service3.svg";
import Service4 from "./SVG/Service4.svg";
import Service5 from "./SVG/Service5.svg";
import Service6 from "./SVG/Service6.svg";
import Service7 from "./SVG/Service7.svg";
import Service8 from "./SVG/Service8.svg";
import Service9 from "./SVG/Service9.svg";

import ServiceBanner from "./Images/Service-Banner.webp";

import Cate1 from "./SVG/prawn 1.svg";
import Cate2 from "./SVG/prawn 2.svg";
import Cate3 from "./SVG/nob 1.svg";
import Cate4 from "./SVG/skewers 1.svg";

import whatsApp from "./SVG/WhatsApp.svg";

//gallery
// import g1 from "./Images/g1.svg";
// import g2 from "./Images/g2.png";
import g3 from "./Images/g3.png";
import g4 from "./Images/g4.png";
import g5 from "./Images/g5.png";
import g6 from "./Images/g6.png";
import g7 from "./Images/g7.png";
import g8 from "./Images/g8.png";
import g9 from "./Images/g9.png";
import rigtd from "./Icons/rigtd.png"
import ledta from "./Icons/ledta.png"

//products

import p8 from "./Images/plachlodr.jpeg"
import p9 from "./Images/placeholdss.svg"
import p10 from "./Images/placeholdsmal.svg"
import p11 from "./Images/homeplachd.svg"
import p12 from "./Images/noimg.svg"

//prutcss
import vanm1 from "./Products/VannameiLitopenaeusVannamei/fresh-white-shrimps-close-up-view-prepared-with-vegetable-herbs-cooking-seafood (1).webp"
import vanm2 from "./Products/VannameiLitopenaeusVannamei/pacific-white-shrimp-isolated-white-background.jpg"
import vanm3 from "./Products/VannameiLitopenaeusVannamei/pile-fresh-uncooked-prawns-market-raw-seafood-texture.webp"
import vanm4 from "./Products/VannameiLitopenaeusVannamei/shrimp-background-fresh-raw-shrimp.webp"
// import vanm4 from "./Products/VannameiLitopenaeusVannamei/shrimp-background-fresh-raw-shrimp.jpg"


import flowr1 from "./Products/FlowerPrawnPenaeussemisulcatus/freshly-caught-shrimp-closeup-background.webp"
import flowr2 from "./Products/FlowerPrawnPenaeussemisulcatus/raw-shrimp-market.webp"
import flowr3 from "./Products/FlowerPrawnPenaeussemisulcatus/raw-shrimp-market.webp"


import kara1 from "./Products/Poovalan-KarikadiMetapenaeusDobsoni-Paraenaeopsis stylifera/many-fresh-shrimps-table_11zon.webp"
import kara2 from "./Products/Poovalan-KarikadiMetapenaeusDobsoni-Paraenaeopsis stylifera/karikadi-shrimps-1.webp"
import kara3 from "./Products/Poovalan-KarikadiMetapenaeusDobsoni-Paraenaeopsis stylifera/shrimp-raw-gambas-fresh-seafood-prawn-meal-food-snack-table-copy-space-food-background_11zon.webp"
import kara4 from "./Products/Poovalan-KarikadiMetapenaeusDobsoni-Paraenaeopsis stylifera/shrimps-wooden-board-raw-seafood-dark-background-top-view-uncooked-food-with-copy-space_11zon.webp"

import seaw1 from "./Products/SeaWhitePrawnPenaeusindicus/raw-shrimp-white-wooden.jpg"
import seaw2 from "./Products/SeaWhitePrawnPenaeusindicus/raw-fresh-shrimps-prawns-isolated-white-background.jpg"
import seaw3 from "./Products/SeaWhitePrawnPenaeusindicus/raw-whole-prawns.jpg"
import seaw4 from "./Products/SeaWhitePrawnPenaeusindicus/six-pawns-with-white-background.jpg"

import blk1 from "./Products/BlackTigerPenaeusMonodon/male-hands-holding-fishing-net-with-fresh-shrimps_11zon (1).webp"
import blk2 from "./Products/BlackTigerPenaeusMonodon/raw-shrimp-stone-board-with-parsley-tomatoes_11zon (1).webp"
import blk3 from "./Products/BlackTigerPenaeusMonodon/tiger-prawn-shrimp (1).webp"
import blk4 from "./Products/BlackTigerPenaeusMonodon/tiger-prawn-shrimp.webp"

import fresh1 from "./Products/FreshWaterScampiMacrobrachiumrosenbergii/high-angle-view-shrimps-bowl-table (1)_11zon.webp"
import fresh2 from "./Products/FreshWaterScampiMacrobrachiumrosenbergii/large-fresh-river-prawns-ready-cook-decorated-with-beautiful-side-dishes (1)_11zon.webp"
import fresh3 from "./Products/FreshWaterScampiMacrobrachiumrosenbergii/Peeled-Undeveined-Flower-Shrimps.webp"

import deep1 from "./Products/Deepcutcookedmarinatedproducts/bbq-spiced-shrimp-with-herbs-spices-scattered-around 1.jpg"
import deep2 from "./Products/Deepcutcookedmarinatedproducts/delicious-seafood-table.jpg"
import deep3 from "./Products/Deepcutcookedmarinatedproducts/fried-shrimps-with-herbs-close-up-view.jpg"

import heal1 from "./Products/Head-LessShell-On/close-up-raw-shrimp.jpg"
import heal2 from "./Products/Head-LessShell-On/close-up-raw-shrimps-wooden-background.jpg"
import heal3 from "./Products/Head-LessShell-On/close-up-raw-shrimps-wooden-background (1).jpg"
import heal4 from "./Products/Head-LessShell-On/fresh-raw-black-shrimps-prawns-stone-board-black-background-top-view.jpg"

import hedo1 from "./Products/Head-OnShell-On/fresh-shrimp-prawn (7).jpg"
import hedo2 from "./Products/Head-OnShell-On/fresh-shrimp-prawn (8).jpg"
import hedo3 from "./Products/Head-OnShell-On/raw-shrimp-isolated-white.jpg"

import nob1 from "./Products/Nobashi/fresh-shrimp-prawn (1).jpg"
import nob2 from "./Products/Nobashi/fresh-shrimp-prawn (2).jpg"
import nob3 from "./Products/Nobashi/fresh-shrimp-prawn (3).jpg"
import nob4 from "./Products/Nobashi/fresh-shrimp-prawn (5).jpg"

import peelo1 from "./Products/PeeledTail-On/cooked-prawns-white-plate-brown-wooden-table.jpg"
import peelo2 from "./Products/PeeledTail-On/two-tail-shrimp-with-fresh-lemon-rosemary-white (1).jpg"
import peelo3 from "./Products/PeeledTail-On/two-tail-shrimp-with-fresh-lemon-rosemary-white (2).jpg"

import peeld1 from "./Products/PeeledandDeveined/fresh-shrimp-fresh-vegetables-cooked-shrimps-prawns-seafood-with-herb-cannabis-leaf-coriander-cooking-boiled-shrimp.jpg"
import peeld2 from "./Products/PeeledandDeveined/seafood-dish.jpg"
import peeld3 from "./Products/PeeledandDeveined/two-tail-shrimp-with-fresh-lemon-rosemary-white.jpg"

import peelu1 from "./Products/PeeledandUnDeveined/close-up-apple-against-white-background.jpg"
import peelu2 from "./Products/PeeledandUnDeveined/fresh-shrimp.jpg"
import peelu3 from "./Products/PeeledandUnDeveined/shrimps-white-background.jpg"

import skw1 from "./Products/Skewers/close-up-raw-shrimps-baking-paper.jpg"
import skw2 from "./Products/Skewers/seafood-shrimp-skewers-blurred-lemon-slices.jpg"
import skw3 from "./Products/Skewers/seafood-shrimp-skewers-herbs.jpg"
import skw4 from "./Products/Skewers/shrimp-kebab-skewer-prawns-seafood-snack-meal-diet-vegetarian.jpg"


import seatiger from "./Products/SeaTiger-HON/raw-big-tiger-prawn.jpg"

import squid from "./Products/Squid-Whole/fresh-squid (1)_11zon.webp"
import squidwhole from "./Products/squid-Wholecleaned/fresh-squid-wooden-backgroundfresg-seafood-squid-ready-cook (1)_11zon.webp"
import squidring from "./Products/Squid -tube&rings/raw-cuttlefish-meat-with-ingredients-paella-black-concrete-surface_11zon.webp"

import cuttlefish from "./Products/Cuttlefish-Wholecleaned/raw-mini-cuttlefish-table_11zon.webp"
import cuttlefish1 from "./Products/Cuttlefish-Wholecleaned/seafood-squid-ice-cooking-food-restaurant-fresh-raw-octopus-cuttlefish-ocean-gourmet.webp"

import octopus from './Products/octopus-whole/whiteraw-squid-seafood-fresh-market.webp'

import blueswimming from './Products/Blue-Swimming-crab/colorful-blue-crabs-ice-market_11zon.webp'
import blueswimming1 from './Products/Blue-Swimming-crab/colorful-blue-crabs-ice-market_11zon.webp'
import blueswimming2 from './Products/Blue-Swimming-crab/fresh-crab.webp'
import blueswimming3 from './Products/Blue-Swimming-crab/fresh-seafood-blue-crab-wooden-background-ready-cook_11zon.webp'


import indianmark from './Products/Indian-Mackeral/ikan-kembung-kembung-fish-mackerel-fish-wooden-chopping-board (1)_11zon.webp'
import indianmark1 from './Products/Indian-Mackeral/short-mackerel-white-background.webp'

import bercudda from './Products/barracuda/obtuse-barracuda-fish (1) (1).webp'

const Assets = {
  Logo,
  succestk,
  Carousel2,
  Banner2,
  Email,
  Phone,
  Location,
  MarketInsight,
  WhoWe,
  Right1,
  Left1,
  conatctban,
  Humble,
  One,
  Two,
  Three,
  Arrow1,
  Arrow2,
  Offer1,
  Offer2,
  Offer3,
  Offer4,
  Offer5,
  ServiceBanner,
  Our1,
  Our2,
  Our3,
  Our4,
  Our5,
  Our6,
  Our7,
  Our8,
  Service2,
  Service3,
  Service4,
  Service5,
  Service6,
  Service7,
  Service8,
  Service9,
  Cate1,
  Cate2,
  Cate3,
  Cate4,
  // g1,
  // g2,
  g3,
  g4,
  g5,
  g6,
  g7,
  g8,
  g9,
  whatsApp,
  ledta,
  rigtd,
 
  
  p8,
  p9,
  p10,
  p11,
  p12,

vanm1,
vanm2,
vanm3,
vanm4,
flowr1,
flowr2,
flowr3,
kara1,
kara2,
kara3,
kara4,
seaw1,
seaw2,
seaw3,
seaw4,
blk1,
blk2,
blk3,
blk4,

fresh1,
fresh2,
fresh3,
deep1,
deep2,
deep3,
heal1,
heal2,
heal3,
heal4,
hedo1,
hedo2,
hedo3,
nob1,
nob2,
nob3,nob4,
peeld1,
peeld2,
peeld3,
peelo1,
peelo2,
peelo3,
peelu1,
peelu2,
peelu3,
skw1,skw2,skw3,skw4,

seatiger,
squid,
squidwhole,
squidring,
cuttlefish,
octopus,
blueswimming,
blueswimming1,
blueswimming2,
blueswimming3,
indianmark,
indianmark1,
bercudda,
cuttlefish1


};


export default Assets;
