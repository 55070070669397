import React from "react";
import "./TopCarousels.sass";
import CarouselWithButton from "../../../components/HomeCarousel/CarouselWithButton";

const TopCarousels = () => {
  return (
    <div className="Carousel-Container">
      <CarouselWithButton />
    </div>
  );
};

export default TopCarousels;
