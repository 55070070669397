// src/components/Header/Header.js
import React, { useEffect, useState, useRef } from "react";
import "./Header.sass";
import Assets from "../../assets/Assets";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

const navBars = [
  { title: "Home", linkTo: "/" },
  { title: "About Us", linkTo: "/about" },
  { title: "Services", linkTo: "/ourservices" },
  { title: "Products", linkTo: "/products" },
  { title: "Gallery", linkTo: "/gallery" },
  { title: "Contact Us", linkTo: "/contact" },
];

const Header = () => {
  const location = useLocation();
  const [scrollPosition, setScrollPosition] = useState(0);
  const [activePage, setActivePage] = useState("");
  const menuToggleRef = useRef(null); // Reference for the menu checkbox

  useEffect(() => {
    const currentPath = location.pathname;
    setActivePage(currentPath);
    if (currentPath === "/products/details") {
      setActivePage("/products");
    }

    // Uncheck the menu-toggle checkbox when location changes
    if (menuToggleRef.current) {
      menuToggleRef.current.checked = false;
    }
  }, [location.pathname]);

  useEffect(() => {
    const handleScroll = () => {
      const position = window.pageYOffset;
      setScrollPosition(position);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleLinkClick = () => {
    // Close the menu by unchecking the checkbox
    if (menuToggleRef.current) {
      menuToggleRef.current.checked = false;
    }
  };

  return (
    <>
      <div className={`navbar ${scrollPosition > 0 ? "scrolled" : ""}`}>
        <div className="navbar-container">
          <div className="navbar-logo">
            <Link to="/">
              <img src={Assets.Logo} alt="Logo" />
            </Link>
          </div>
          <ul className="navbar-nav">
            {navBars?.map((data, index) => (
              <li className="nav-item" key={index}>
                <Link
                  to={data?.linkTo}
                  className={`nav-link ${
                    activePage === data?.linkTo ? "active" : ""
                  }`}
                >
                  {data?.title}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="mobile">
        <section className="top-nav">
          <div>
            <img src={Assets.Logo} alt="Logo" />
          </div>
          <input
            id="menu-toggle"
            type="checkbox"
            ref={menuToggleRef} // Assign the ref to the checkbox
          />
          <label className="menu-button-container" htmlFor="menu-toggle">
            <div className="menu-button"></div>
          </label>
          <ul className="menu">
            {navBars?.map((data, index) => (
              <li className="nav-item" key={index}>
                <Link
                  to={data?.linkTo}
                  className={`nav-link ${
                    activePage === data?.linkTo ? "active" : ""
                  }`}
                  onClick={handleLinkClick} // Close the menu when clicking a link
                >
                  {data?.title}
                </Link>
              </li>
            ))}
          </ul>
        </section>
      </div>
    </>
  );
};

export default Header;
