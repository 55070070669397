import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./CarouselCaption.sass";
import Assets from "../../assets/Assets";

function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <div onClick={onClick} className={className}>
      <img src={Assets.Arrow2} alt="arrow-2" style={{ width: "40px" }} />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div onClick={onClick} className={`${className} custom-arrow`}>
      <img src={Assets.Arrow1} alt="arrow-1" style={{ width: "40px" }} />
    </div>
  );
}

const CarouselCaption = () => {
  const settings = {
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    autoplay: false,
    autoplaySpeed: 3000,
  };

  const cards = [
    {
      id: 1,
      icon: Assets.deep1,
      title: "Deep cut cooked marinated products ​​",
    },
    {
      id: 2,
      icon: Assets.nob1,
      title: "Nobashi",
    },
    {
      id: 3,
      icon: Assets.skw1,
      title: "Skewers ​",
    },
    {
      id: 4,
      icon: Assets.hedo1,
      title: "Head-On Shell-On (HOSO)​",
    },
    {
      id: 5,
      icon: Assets.heal1,
      title: "Head-Less Shell-On (HLSO)​",
    },
    {
      id: 6,
      icon: Assets.peeld1,
      title: "Peeled and Deveined (PD)​",
    },
    {
      id: 7,
      icon: Assets.peelu1,
      title: "Peeled and UnDeveined (PUD)​",
    },
    {
      id: 8,
      icon: Assets.peelo1,
      title: "Peeled Tail-On (PTO)​",
    },
  ];

  return (
    <div className="card-carousel-container">
      <Slider {...settings}>
        {cards.map((card) => (
          <div key={card.id} className="carousel-caption-text-new">
            <div className="carousel-caption-holder">
              <img src={card.icon} alt={card.id} />
              <span className="carousel-caption-heading-text">
                {card.title}
              </span>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default CarouselCaption;
