// src/layout/Layout/Layout.js
import React from "react";
import "./Layout.sass";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import FloatingMenu from "../FloatingMenu/FloatingMenu";

const Layout = ({ children }) => {

  return (
    <div className="layout">
      <Header />
      <main className="main-content">{children}</main>
      <FloatingMenu />
      <Footer />
    </div>
  );
};

export default Layout;
