// src/App.js
import React, { useEffect } from "react";
import "./App.sass";
import RoutesComponent from "./routes";

function App() {
  return (
    <div className="App">
      <RoutesComponent />
    </div>
  );
}

export default App;
