import React from "react";
import "./FloatingMenu.sass";
import Assets from "../../assets/Assets";

const FloatingMenu = () => {
  const phoneNumber = '918056777546'
  const goTo = () => {
    let url = `https://api.whatsapp.com/send?phone=${phoneNumber}`;
    window.open(url, "_blank");
  };
  return (
    <div className="floating-menu" onClick={goTo}>
      <img src={Assets.whatsApp} alt="whatsApp" />
    </div>
  );
};

export default FloatingMenu;
