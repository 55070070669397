import React from "react";
import "./DetailsPage.sass";
import Meta from "../../../components/metaData/Meta";
import ProductDetails from "./ProductDetails/ProductDetails";
import RelatedProducts from "./RelatedProducts/RelatedProducts";

const DetailsPage = () => {
  return (
    <div className="detail-main-container">
      <Meta
        title="SSB | Products Details"
        description="Description of the About page"
      />
      <div class="product-detail-container">
        <ProductDetails />
      </div>
      <div>
        <RelatedProducts />
      </div>
    </div>
  );
};

export default DetailsPage;
