import React from "react";
import { Link, useNavigate } from "react-router-dom"; // Import Link and useNavigate from react-router-dom
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./MultiRowCarousel.sass"; // Make sure to create this CSS file
import Assets from "../../assets/Assets";

const ArrayofData = [
  { id: 1, img: Assets.vanm1, link: "/page1" },
  { id: 2, img: Assets.seaw1, link: "/page2" },
  { id: 3, img: Assets.blk1, link: "/page3" },
  { id: 4, img: Assets.deep1, link: "/page4" },
  { id: 5, img: Assets.flowr1, link: "/page5" },
  { id: 6, img: Assets.fresh1, link: "/page6" },
  { id: 7, img: Assets.kara1, link: "/page7" },
  { id: 8, img: Assets.skw1, link: "/page8" },
];

function MultiRowCarousel(props) {
  const navigate = useNavigate();
  const { className, onClick } = props;
  
  const settings = {
    className: "center",
    centerMode: false,
    infinite: true,
    centerPadding: "0px",
    slidesToShow: 4,
    speed: 500,
    rows: 2,
    slidesPerRow: 1,
    arrows: false,
    dots: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  const goToDetailPage = (id) => {
    navigate(`/products/details`, { state: { id } });
  };

  return (
    <div className="slider-container-old">
      <Slider {...settings} className="slider-2-old">
        {ArrayofData.map((data) => (
          <div 
            key={data.id} 
            className="carousel-item-old"
            onClick={() => goToDetailPage(data.id)}
            style={{ cursor: 'pointer' }}
          >
            <img
            style={{width:"300px",height:"280px"}}
              src={data.img}
              alt={`Slide ${data.id}`}
              className="carousel-image-old"
            />
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default MultiRowCarousel;
