import React from "react";
import "./Services.sass";
import Assets from "../../../assets/Assets";
import { useScreenSize } from "../../../utills/helpers";
import { useNavigate } from "react-router-dom";

const Services = () => {
  const isMobile = useScreenSize();
  const navigate = useNavigate();
  const goTo = () => {
    navigate("/ourservices");
  };
  return (
    <div className="Our-services">
      <div className="services-container">
        <span className="text-title">Our Services</span>
        <div className="row-container">
          <div className="row-1">
            <div className="iconandtitle">
              <img src={Assets.MarketInsight} alt="market-insight" />
              <span className="icon-text">
                Market <br />
                Insights
              </span>
            </div>
            <div className="iconandtitle">
              <img src={Assets.Service2} alt="market-insight" />
              <span className="icon-text">Sourcing Excellence</span>
            </div>
            <div className="iconandtitle">
              <img src={Assets.Service4} alt="market-insight" />
              <span className="icon-text">Strategic Price Negotiation</span>
            </div>
            <div className="iconandtitle">
              <img src={Assets.Service3} alt="market-insight" />
              <span className="icon-text">Shipment Oversight</span>
            </div>
            <div className="iconandtitle">
              <img src={Assets.Service5} alt="market-insight" />
              <span className="icon-text">Processor Accreditation</span>
            </div>
            {isMobile && (
              <>
                <div className="iconandtitle-2">
                  <img src={Assets.Service6} alt="market-insight" />
                  <span className="icon-text">Buying Strategy Development</span>
                </div>
                <div className="iconandtitle-2">
                  <img src={Assets.Service7} alt="market-insight" />
                  <span className="icon-text">
                    Quality Control: Safety Assurance
                  </span>
                </div>
                <div className="iconandtitle-2">
                  <img src={Assets.Service8} alt="market-insight" />
                  <span className="icon-text">
                    Packaging and Labelling Support
                  </span>
                </div>
                <div className="iconandtitle-2">
                  <img src={Assets.Service9} alt="market-insight" />
                  <span className="icon-text">Logistics and Documentation</span>
                </div>
              </>
            )}
          </div>
          {!isMobile && (
            <div className="row-2">
              <div className="iconandtitle-2">
                <img src={Assets.Service6} alt="market-insight" />
                <span className="icon-text">Buying Strategy Development</span>
              </div>
              <div className="iconandtitle-2">
                <img src={Assets.Service7} alt="market-insight" />
                <span className="icon-text">
                  Quality Control: Safety Assurance
                </span>
              </div>
              <div className="iconandtitle-2">
                <img src={Assets.Service8} alt="market-insight" />
                <span className="icon-text">
                  Packaging and Labelling Support
                </span>
              </div>
              <div className="iconandtitle-2">
                <img src={Assets.Service9} alt="market-insight" />
                <span className="icon-text">Logistics and Documentation</span>
              </div>
            </div>
          )}
        </div>
        <span className="know-more" onClick={goTo}>
          Know more {">"}
        </span>
      </div>
    </div>
  );
};

export default Services;
