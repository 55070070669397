import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./CarouselWithButton.sass"; // Create a CSS file for custom styling if needed
import Assets from "../../assets/Assets";
import PrimaryButton from "../Button/PrimaryButton/PrimaryButton";
import { useNavigate } from "react-router-dom";

const CarouselWithButton = () => {
  const navigate = useNavigate();
  const goTo = (to) => {
    navigate(to);
  };
  const slides = [
    {
      image: Assets.Banner2,
      text: "Welcome to SSB&Co",
      subText: "Sea to Source and Farm to Source",
      button: "Contact Us",
      to: "/contact",
    },
    {
      image: Assets.Banner2,
      text: `Who we are ?`,
      subText: "Experts in procurement, packaging, QA, and Logistics​",
      button: "Know more",
      to: "/contact",
    },
    {
      image: Assets.Banner2,
      text: "Why should you choose us ?​",
      subText: "From product to sourcing, we match your exact specifications​",
      button: "Know more",
      to: "/contact",
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    rtl: false, // Enable right-to-left scroll
    arrows: false, // Hide arrows for clean look, optional
    appendDots: (dots) => (
      <div>
        <ul> {dots} </ul>
      </div>
    ),
  };

  return (
    <div className="carousel-container">
      <Slider {...settings}>
        {slides.map((slide, index) => (
          <div key={index} className="slide">
            {/* <img src={slide.image} alt={`Slide ${index + 1}`} /> */}
            <div className="overlay-container">
              {/* <img
                src={Assets.Carousel2}
                alt={`Slide Blurred ${index + 1}`}
                className="blurred-image"
              />
              <div className="gradient-overlay"></div> */}
              <img
                src={slide.image}
                alt={`Slide ${index + 1}`}
                className="bright-image"
              />
            </div>
            <div className="slide-text">
              <div>
                <h1>{slide?.text} </h1>
                <span>{slide?.subText}</span>
              </div>
              <div className="btn-container-carousel">
                <PrimaryButton onClick={() => goTo(slide?.to)}>
                  Contact Us
                </PrimaryButton>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default CarouselWithButton;
