import React from "react";
import "./RelatedProducts.sass";
import CarouselImgwithText from "../../../../components/CarouselImgwithText/CarouselImgwithText";

const RelatedProducts = () => {
  return (
    <div className="productdetails-9-div">
      <CarouselImgwithText />
    </div>
  );
};

export default RelatedProducts;
