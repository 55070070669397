import React, { useEffect, useState } from "react";
import "./ProductDetails.sass";
import SearchDropDown from "../../../../components/DropDown/SearchDropDown/SearchDropDown";
import ImageGallery from "../../../../components/ImageGallery/ImageGallery";
import Assets from "../../../../assets/Assets";
import SimpleTable from "../../../../components/Table/SimpleTable";
import { useLocation, useNavigate } from "react-router-dom";

const ProductDetails = () => {
  const options = [
    { label: "Option 1", value: "option1", color: "blue" },
    { label: "Option 2", value: "option2", color: "green" },
    { label: "Option 3", value: "option3", color: "red" },
    { label: "Option 4", value: "option4", color: "orange" },
    { label: "Option 5", value: "option5", color: "purple" },
  ];

  const [selectedOption, setSelectedOption] = useState(null);
  const [product, setProduct] = useState(null);

  const handleChange = (option) => {
    setSelectedOption(option);
  };

  // const images = [
  //   Assets.p8,
  //   Assets.vanm2,
  //   Assets.vanm3,
  // ];
  const tableData = [
    {
      ExtraColossal: "U10",
      SuperColossal: "U12",
      Colossal: "U15",
      ExtraJumbo: "16 / 20",
      Jumbo: "21 / 25",
      Extralarge: "26 / 30",
      Large: "31 / 35",
      MediumLarge: "36 / 42",
      Medium: "43 / 50",
      Small: "51 / 60",
      ExtraSmall: "61 / 70",
      Tiny: "Over 70",
    },
  ];

  const location = useLocation();
  const { id } = location.state;
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };


  const products = [
    {
      id: 1,
      title: "Vannamei",
      content: "Litopenaeus Vannamei",
      productTypes: `Head-On Shell-On (HOSO),
Head-Less Shell-On(HLSO),
Peeled and Deveined (PD),
Peeled and UnDeveined (PUD),
Peeled Tail-On(PTO),
Peeled Deveined Tail-On (PDTO)`,
      cuts: "Butterfly-Cut, Pulled-Vein, Easy Peel",
      packing: {
        block: "6 x 2kg, 6 x 1.8 kg, 6 x 900 gm, 6 x 800 gm",
        iqf: "10 x 1 Kg & Custom"
      },
      size: {
        block: "6/8, 8/12, 13/15, 16/20, 21/25, etc.",
        iqf: "6/8, 8/12, 13/15, 16/20, 21/25, etc."
      },
      images: [Assets.vanm1, Assets.vanm2, Assets.vanm3],
    },
    {
      id: 2,
      title: "Sea White Prawn",
      content: "Penaeus indicus",
      productTypes: `Head-On Shell-On (HOSO),
Head-Less Shell-On(HLSO),
Peeled and Deveined (PD),
Peeled and UnDeveined (PUD),
Peeled Deveined Tail-On(PDTO)`,
      cuts: "Butterfly-Cut, Easy Peel",
      packing: {
        block: "6 x 2 Kg, 6 x 1.8 Kg",
        iqf: "10 x 1 Kg & Custom"
      },
      size: {
        block: "U/3, 3/6, 6/10, 10/20, etc.",
        iqf: "2/4, 4/6, 6/8, 8/12, 13/15, 16/20, etc."
      },
      images: [Assets.seaw1, Assets.seaw2, Assets.seaw3],
    },
    {
      id: 3,
      title: "Sea Tiger",
      content: "Penaeus Monodon",
      productTypes: `Head-On Shell-On (HOSO),
Head-Less Shell-On(HLSO),
Peeled and Deveined (PD),
Peeled and UnDeveined (PUD),
Peeled Deveined Tail-On(PDTO)`,
      cuts: "Butterfly-Cut, Pulled-Vein, Easy Peel",
      packing: {
        block: "6 x 2 Kg, 6 x 1.8 Kg",
        iqf: "10 x 1 Kg & Custom"
      },
      size: {
        block: "U/3, 3/6, 6/10, 10/20, etc.",
        iqf: "2/4, 4/6, 6/8, 8/12, 13/15, 16/20, etc."
      },
      images: [Assets.seatiger, Assets.seatiger, Assets.seatiger],
    },
    {
      id: 4,
      title: "Black Tiger",
      content: "Penaeus Monodon",
      productTypes: `Head-On Shell-On (HOSO),
Head-Less Shell-On(HLSO),
Peeled and Deveined (PD),
Peeled and UnDeveined (PUD),
Peeled Deveined Tail-On(PDTO)`,
      cuts: "Butterfly-Cut, Pulled-Vein, Easy Peel",
      packing: {
        block: "6 x 2 Kg, 6 x 1.8 Kg",
        iqf: "10 x 1 Kg & Custom"
      },
      size: {
        block: "U/3, 3/6, 6/10, 10/20, etc.",
        iqf: "2/4, 4/6, 6/8, 8/12, 13/15, 16/20, etc."
      },
      images: [Assets.blk1, Assets.blk2, Assets.blk3],
    },
    {
      id: 5,
      title: "Flower Prawn",
      content: "(Penaeus semisulcatus)",
      productTypes: `Head-On Shell-On (HOSO),
Head-Less Shell-On(HLSO),
Peeled and Deveined (PD),
Peeled and UnDeveined (PUD),
Peeled Deveined Tail-On(PDTO)`,
      cuts: "Butterfly-Cut, Pulled-Vein, Easy Peel",
      packing: {
        block: "6 x 2 Kg, 6 x 1.8 Kg",
        iqf: "10 x 1 Kg & Custom"
      },
      size: {
        block: "U/3, 3/6, 6/10, 10/20, etc.",
        iqf: "2/4, 4/6, 6/8, 8/12, 13/15, 16/20, etc."
      },
      images: [Assets.flowr1, Assets.kara2, Assets.kara3],
    },
    {
      id: 6,
      title: "Fresh Water Scampi",
      content: "Macrobrachium rosenbergii",
      productTypes: `Head-On Shell-On (HOSO),
Head-Less Shell-On(HLSO)`,
      cuts: "",
      packing: {
        iqf: "10 x 1 Kg & Custom"
      },
      size: {
        iqf: "2/4, 4/6, 6/8, 8/12, 13/15, 16/20, etc."
      },

      images: [Assets.fresh1, Assets.fresh2, Assets.fresh1],
    },
    {
      id: 7,
      title: "Poovalan - Karikadi",
      content: "Metapenaeus Dobsoni - Paraenaeopsis stylifera",
      productTypes: `Peeled and UnDeveined (PUD)`,
      cuts: "",
      packing: {
        block: "6 x 2 kg",
        iqf: "10 x 1Kg & Custom"
      },
      size: {
        block: "100/200, 200/300",
        iqf: "100/200, 200/300, 300/500"
      },

      images: [Assets.kara1, Assets.kara2, Assets.kara1],
    },
//     {
//       id: 8,
//       title: "CEPHALOPODS",
//       content: "Metapenaeus Dobsoni - Paraenaeopsis stylifera",
//       productTypes: `Head-On Shell-On (HOSO),
// Head-Less Shell-On(HLSO),
// Peeled and Deveined (PD),
// Peeled and UnDeveined (PUD),
// Peeled Tail-On(PTO),
// Peeled Deveined Tail-On (PDTO)`,
//       cuts: "Butterfly-Cut, Pulled-Vein, Easy Peel, Broken",
//       packing: {
//         block: "6 x 2kg, 6 x 1.8 kg, 6 x 900 gm, 6 x 800 gm",
//         iqf: "10 x 1 Kg & Custom"
//       },
//       size: {
//         block: "6/8, 8/12, 13/15, 16/20, 21/25, etc.",
//         iqf: "6/8, 8/12, 13/15, 16/20, 21/25, etc."
//       },

//       images: [Assets.p9, Assets.p9, Assets.p9],
//     },
//     {
//       id: 9,
//       title: "SQUID",
//       content: "Loligo Sp",
//       productTypes: `Head-On Shell-On (HOSO),
// Head-Less Shell-On(HLSO),
// Peeled and Deveined (PD),
// Peeled and UnDeveined (PUD),
// Peeled Tail-On(PTO),
// Peeled Deveined Tail-On (PDTO)`,
//       cuts: "Butterfly-Cut, Pulled-Vein, Easy Peel, Broken",
//       packing: {
//         block: "6 x 2kg, 6 x 1.8 kg, 6 x 900 gm, 6 x 800 gm",
//         iqf: "10 x 1 Kg & Custom"
//       },
//       size: {
//         block: "6/8, 8/12, 13/15, 16/20, 21/25, etc.",
//         iqf: "6/8, 8/12, 13/15, 16/20, 21/25, etc."
//       },

//       images: [Assets.squid, Assets.squidring, Assets.squidwhole],
//     },
//     {
//       id: 10,
//       title: "CUTTLEFISH",
//       content: "Sepia Sp",
//       productTypes: `Squid Whole,
// Squid Whole Cleaned,
// Squid Tube,
// Squid Rings,
// Squid Tentacles,
// Squid stuffed,
// Cuttlefish Whole,
// Cuttlefish Whole Cleaned,
// Baby Octopus Whole,
// Baby Octopus Cleaned`,
//       cuts: "Butterfly-Cut, Pulled-Vein, Easy Peel, Broken",
//       packing: {
//         block: "6 x 2Kg, 5 X 4Kg",
//         iqf: "10 x 1Kg & Custom"
//       },
//       size: {
//         block: "U/3, 3/6,10/20,20/30, etc.",
//         iqf: "10/20, 20/40,40/60,60/UP etc."
//       },

//       images: [Assets.cuttlefish, Assets.cuttlefish1, Assets.cuttlefish],
//     },
//     {
//       id: 11,
//       title: "OCTOPUS",
//       content: "Octopus Membranaceous, Octopus Dollfusi",
//       productTypes: `Head-On Shell-On (HOSO),
// Head-Less Shell-On(HLSO),
// Peeled and Deveined (PD),
// Peeled and UnDeveined (PUD),
// Peeled Tail-On(PTO),
// Peeled Deveined Tail-On (PDTO)`,
//       cuts: "Butterfly-Cut, Pulled-Vein, Easy Peel, Broken",
//       packing: {
//         block: " 6 x 2Kg, 5 X 4Kg",
//         iqf: "10 x 1Kg & Custom"
//       },
//       size: {
//         block: "U/3, 3/6,10/20,20/30, etc ",
//         iqf: "10/20, 20/40,40/60,60/UP etc."
//       },


//       images: [Assets.octopus, Assets.octopus, Assets.octopus],
//     },

    {
      id: 12,
      title: "Blue Swimming Crab",
      content: "Portunus pelagicus",
      productTypes: "Crab Whole ,Cut Crab",
      cuts: "",

      packing: {
        block: "",
        iqf: " 10 x 1Kg & 2 x 5 kg  Custom"
      },
      size: {
        block: "",
        iqf: "50/100,100/150 &150/200  etc."
      },
      images: [
        Assets.blueswimming1,
        Assets.blueswimming2,
        Assets.blueswimming3,
      ],
    },
    {
      id: 13,
      title: "SEER FISH",
      content: "Scomberomus ",
      productTypes: "Whole ,Whole Gutted ,H/L ,Whole Gutted and scaled",
      cuts: "",

      packing: {
        block: "",
        iqf: "  10 x 1Kg & 1 x 25 kg  Custom"
      },
      size: {
        block: "",
        iqf: " As per customer etc.."
      },

      images: [
        Assets.p9,
        Assets.p9,
        Assets.p9,
      ],
    },
    {
      id: 14,
      title: "Indian Mackerel",
      content: "Rastrelliger kanagurta",
      productTypes: "Whole rounded",
      cuts: "",
      // packing: "IF : 10 x 1Kg & 1 x 25 kg  Custom",
      // size: "IF :  As per customer etc. ",
      packing: {
        block: "",
        iqf: "  10 x 1Kg & 1 x 25 kg  Custom"
      },
      size: {
        block: "",
        iqf: "2/4, 4/6, 6/8, 8/10 & 10/12"
      },
      images: [
        Assets.indianmark,
        Assets.indianmark1,
        Assets.indianmark,
      ],
    },
    {
      id: 15,
      title: "Horse Mackerel",
      content: "Megalapsis cordyla",
      productTypes: "Whole rounded",
      cuts: "",

      packing: {
        block: "",
        iqf: "  10 x 1Kg & 1 x 25 kg  Custom"
      },
      size: {
        block: "",
        iqf: "2/4, 4/6, 6/8, 8/10 & 10/12"
      },
      images: [
        Assets.p9,
        Assets.p9,
        Assets.p9,
      ],
    },
    {
      id: 16,
      title: "Emperor",
      content: "Lutjanus sebae",
      productTypes: "Whole ,Whole Gutted ,H/L ,Whole Gutted and scaled",
      cuts: "",
      packing: {
        block: "",
        iqf: "  10 x 1Kg & 1 x 25 kg  Custom"
      },
      size: {
        block: "",
        iqf: " As per customer etc.."
      },

      images: [
        Assets.p9,
        Assets.p9,
        Assets.p9,
      ],
    },
    {
      id: 17,
      title: "Barracuda",
      content: "Spyrenea Jello",
      productTypes: "Whole ,Whole Gutted",
      cuts: "",
      packing: {
        block: "",
        iqf: "  10 x 1Kg & 1 x 25 kg  Custom"
      },
      size: {
        block: "",
        iqf: " As per customer etc.."
      },

      images: [
        Assets.bercudda,
        Assets.bercudda,
        Assets.bercudda,
      ],
    },

    {
      id: 18,
      title: "CEPHALOPODS SQUID",
      content: "Loligo Sp",
      productTypes: `Squid Whole,
          Squid Whole Cleaned,
          Squid Tube,
          Squid Rings,
          Squid Tentacles,
          Squid stuffed`,
      cuts: "",
      packing: {
        block: "6 x 2 kg & 5 x 4 kg  ",
        iqf: "10 x 1 kg, 1 x 10 kg  "
      },
      size: {
        block: "U/3, 3/6/ 6/10, 10/20, 20/40, 40/60, 60/80 & 80/UP",
        iqf: "As per customer etc.."
      },

      images: [Assets.squid, Assets.squidring, Assets.squidwhole],
    },

    {
      id: 19,
      title: "CEPHALOPODS CUTTLEFISH",
      content: "Sepia Sp",
      productTypes: `Cuttlefish Whole,
              Cuttlefish Whole Cleaned `,
      cuts: "",
      packing: {
        block: "6 x 2 kg & 5 x 4 kg  ",
        iqf: "10 x 1 kg, 1 x 10 kg  "
      },
      size: {
        block: "U/3, 3/6/ 6/10, 10/20, 20/40, 40/60, 60/80 & 80/UP",
        iqf: "As per customer etc.."
      },

      images: [Assets.cuttlefish, Assets.cuttlefish1, Assets.cuttlefish],
    },

    {
      id: 20,
      title: "CEPHALOPODS OCTOPUS",
      content: "Octopus membranes",
      productTypes: `Baby Octopus Whole,
              Baby Octopus Whole cleaned`,
      cuts: "",
      packing: {
        block: "6 x 2 kg & 5 x 4 kg  ",
        iqf: "10 x 1 kg, 1 x 10 kg  "
      },
      size: {
        block: "U/3, 3/6/ 6/10, 10/20, 20/40, 40/60, 60/80 & 80/UP",
        iqf: " As per customer etc.."
      },

      images: [Assets.octopus, Assets.octopus, Assets.octopus],
    },


  ];

  useEffect(() => {
    console.log("Location state:", location.state);
    const product = products.find((product) => product.id === id);
    console.log("Selected Product:", product);
    setProduct(product);
  }, [id]);

  if (!product) {
    return (
      <div>
        <img src={Assets.p12} style={{ textAlign: "center", width: "100%" }} alt="Not found" />
      </div>
    );
  }


  // const sizeArray = product.size.split(",").map(size => size .trim());
  // const { blockSize, iqfSize } = parseSize(product.size);
  // const [blockSize, iqfSize] = product.size.split(" , ");
  // const [blockPacking, iqfPacking] = product.packing.split(", ");

  return (
    <div className="productdetails-1">
      <div className="productdetails-2">
        <div className="productdetails-3">
          <div>
            <span className="productdetails-span-1" onClick={goBack}>
              Products {"> "}
            </span>
            <span className="productdetails-span-2">
              {product.title} {product.content}
            </span>
          </div>
          {/* <div className="productdetails-4">
            <span className="productdetails-span-3">Products</span>
            <div>
              <SearchDropDown options={options} onChange={handleChange} />
            </div>
          </div> */}
        </div>
        <div className="productdetails-7">
          <div className="productdetails-5">
            <ImageGallery images={product.images} />
          </div>
          <div className="productdetails-6">
            <div className="productdetails-sub-1">
              <div>
                <span className="productdetails-sub-span-1">
                  {product.title} ({product.content})
                </span>
              </div>

              <div className="productdetails-sub-2">
                <div>
                  <span className="productdetails-sub-span-2-bold">
                    Categories :{" "}
                  </span>
                  <span className="productdetails-sub-span-2">
                    {product.productTypes.split(", ").map((type, index) => (
                      <p key={index}>{type}</p>
                    ))}
                  </span>
                </div>
                {product.cuts ? (
                  <div>
                    <span className="productdetails-sub-span-2-bold">
                      Cuts:
                    </span>
                    <span className="productdetails-sub-span-2">
                      {product.cuts.split(", ").map((cut, index) => (
                        <p key={index}>{cut}</p>
                      ))}
                    </span>
                  </div>
                ) : null}

              </div>

              <div className="productdetails-sub-3">
                <div className="productdetails-sub3-left">
                  <span className="productdetails-sub-span-2-bold">
                    Packing
                  </span>
                  <span className="productdetails-sub-span-2">
                    <div style={{ display: "flex", flexDirection: "column", gap: "10px", justifyContent: "space-around" }}>
                    {product.packing.block ? (
                      <>
                      <label style={{ fontWeight: "600" }}>Block :</label>
                      <div>{product.packing.block}</div>
                      </>
                    ) : null}
                      <label style={{ fontWeight: "600" }}>IQF:</label>
                      <div>{product.packing.iqf}</div>
                    </div>
                  </span>
                </div>
                <div className="productdetails-sub3-left">
                  <span className="productdetails-sub-span-2-bold">Size</span>
                  <span className="productdetails-sub-span-2">
                    <div style={{ display: "flex", flexDirection: "column", gap: "10px", justifyContent: "space-around" }}>
                    {product.size.block ? (
                    <>
                      <label style={{ fontWeight: "600" }}>Block:</label>
                      </>
                    ) : null}
                      <div>{product.size.block}</div>
                      <label style={{ fontWeight: "600" }}>IQF:</label>
                      <div>{product.size.iqf}</div>
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="productdetails-8">
          {/* <div className="productdetails-8-sub">
            <span className="productdetails-8-span1-bold">Size Chart</span>
            <span className="productdetails-8-span1">
              ( Common Sizing Term / Shrimp Count Per Pound )
            </span>
          </div> */}
          {/* <SimpleTable data={tableData} /> */}
          <div className="text-center" >
            <span className="productdetails-8-span1-bold">
              Related products
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
