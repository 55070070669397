import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./CardCarousel.sass";
import Assets from "../../assets/Assets";

function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <div onClick={onClick} className={className}>
      <img src={Assets.Arrow2} alt="arrow-2" style={{ width: "40px" }} />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div onClick={onClick} className={className}>
      <img src={Assets.Arrow1} alt="arrow-1" style={{ width: "40px" }} />
    </div>
  );
}

const CardCarousel = () => {
  const settings = {
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },{
        breakpoint: 850,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const cards = [
    {
      id: 1,
      icon: Assets.Offer1,
      title: "Product sourcing",
      content:
        "SSB&CO excels in locating the precise seafood products our clients require, adhering strictly to their detailed specifications.​",
    },
    {
      id: 2,
      icon: Assets.Offer2,
      title: "Price Negotiation",
      content:
        "Our aim is to achieve the most favorable pricing for our customers, balancing affordability with market competitiveness",
    },
    {
      id: 3,
      icon: Assets.Offer3,
      title: "Product Supervision",
      content:
        "From the moment raw materials enter our facilities to the point of shipment, each phase is subject to scrupulous supervision, maintaining our commitment to quality at every juncture.​​",
    },
    {
      id: 4,
      icon: Assets.Offer4,
      title: "Packing Monitoring",
      content:
        "We closely supervise the packaging process, guaranteeing that it meets our stringent standards for quality and safety.​",
    },
    {
      id: 5,
      icon: Assets.Offer5,
      title: "​Shipment Tracking",
      content:
        "SSB&CO ensures detailed shipment tracking, providing transparency and prompt delivery from the point of origin to the destination.",
    },
  ];

  return (
    <div className="card-carousel-container container-fluid">
      <Slider {...settings}>
        {cards.map((card) => (
          <div key={card.id} className="card-container-new">
            <div className="card-new">
              <div className="card-content-holder">
                <img src={card.icon} alt={card.id} />
                <span className="span-heading">{card.title}</span>
                <span className="span-desc">{card.content}</span>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default CardCarousel;
