import React from "react";
import "./WhoWeAre.sass";
import PrimaryButton from "../../../components/Button/PrimaryButton/PrimaryButton";
import Assets from "../../../assets/Assets";
import { useNavigate } from "react-router-dom";

const WhoWeAre = () => {
  const navigate = useNavigate();
  const goTo = () => {
    navigate("/about");
  };
  return (
    <div className="who-container">
      <div className="who-content-1">
        <div className="content-divider">
          <div className="divider-1">
            <div className="content-holder">
              <span className="who-are-we-title">
                Who Are We{" "}
                <span style={{ fontFamily: "sans-serif" }}>{"?"}</span>
              </span>
              <span className="who-are-we-sub-title">
                In the vast expanse of the Frozen Seafood industry, SSB&CO
                refuses to settle for being just another small fish in the big
                sea. Instead, we proudly stand as a beacon of excellence and
                innovation. Despite the challenges of our size, we are working
                to enhance value and forge partnerships that fuel mutual
                growth. Our journey is marked by a determination to navigate the
                competitive.
              </span>
              <PrimaryButton onClick={goTo}>{"Know More"}</PrimaryButton>
            </div>
          </div>
          <div className="divider-2">
            <div className="content-holder-2">
              <div className="image-container">
                <img
                  alt="who-we-are"
                  src={Assets.WhoWe}
                  className="img-style"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhoWeAre;
