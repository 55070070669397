import React from "react";
import Slider from "react-slick";
import Assets from "../../assets/Assets";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Gallery.css";

// Custom Arrow Components
const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      className="gallery-custom-arrow gallery-custom-arrow-next"
      onClick={onClick}
    >
      <img src={Assets.rigtd} alt="Next Arrow" style={{ width: "8px" }} />
    </div>
  );
};

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      className="gallery-custom-arrow gallery-custom-arrow-prev"
      onClick={onClick}
    >
      <img src={Assets.ledta} alt="Prev Arrow" style={{ width: "8px" }}/>
    </div>
  );
};

const GalleryFirstSection = () => {
  const sliders = [
    [
      // Assets.g1,
      // Assets.g2,
      Assets.g3,
      Assets.g4,
      Assets.g5,
      Assets.g6,
      Assets.g7,
      Assets.g8,
      Assets.g9,
    ],
    [
      // Assets.g1,
      // Assets.g2,
      Assets.g3,
      Assets.g4,
      Assets.g5,
      Assets.g6,
      Assets.g7,
      Assets.g8,
      Assets.g9,
    ],
    [
      // Assets.g1,
      // Assets.g2,
      Assets.g3,
      Assets.g4,
      Assets.g5,
      Assets.g6,
      Assets.g7,
      Assets.g8,
      Assets.g9,
    ],
  ];

  const settings = {
    customPaging: (i) => <div className="gallery-custom-dot">{i + 1}</div>,
    dots: true,
    dotsClass: "gallery-slick-dots gallery-slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <div className="firstsectImg">
      <Slider {...settings}>
        {sliders.map((images, sliderIndex) => (
          <div key={sliderIndex} className="slider-content">
            <div className="container">
              <div className="row bigisk">
                <div className="col-lg-8 col-sm-12">
                  <img
                    className="bigimgfi"
                    src={images[0]}
                    alt={`Slider ${sliderIndex + 1} Image 1`}
                  />
                </div>
                <div className="col-lg-4 col-sm-12">
                  <div className="imgsidesaa">
                    {images.slice(1, 3).map((image, index) => (
                      <img
                        key={index}
                        className="sideimgsml"
                        src={image}
                        alt={`Slider ${sliderIndex + 1} Image ${index + 2}`}
                      />
                    ))}
                  </div>
                </div>
              </div>
              <div className="row">
                {images.slice(3, 9).map((image, index) => (
                  <div key={index} className="col-lg-3 col-sm-12">
                    <img
                      src={image}
                      className="subclimg"
                      alt={`Slider ${sliderIndex + 1} Image ${index + 4}`}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default GalleryFirstSection;
