import React, { useState } from "react";
import "./ImageGallery.sass"; // You can add your own CSS for styling

const ImageGallery = ({ images }) => {
  const [currentImage, setCurrentImage] = useState(images[0]);

  const handleClickThumbnail = (image) => {
    setCurrentImage(image);
  };

  return (
    <div className="image-gallery">
      <div className="main-image">
        <div
          style={{
            maxWidth:'100%',
            width: "420px",
            height: "420px",
            borderRadius: "36px",
          }}
        >
          <img src={currentImage} alt="Main" style={{ width: "100%" }} />
        </div>
      </div>
      <div className="thumbnails">
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Thumbnail ${index}`}
            onClick={() => handleClickThumbnail(image)}
          />
        ))}
      </div>
    </div>
  );
};

export default ImageGallery;
